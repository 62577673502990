import React, { useState } from "react";
import { DayPicker } from 'react-day-picker';
import { addDays, subMonths, addMonths } from 'date-fns';
import { useTranslation } from 'react-i18next';
import 'react-day-picker/dist/style.css';
import { convertToEthiopianDate, formatTime } from '../../utils/convertTime';
import { FaBuildingColumns } from "react-icons/fa6";

const DateSelection = ({ selectedDate, setSelectedDate, selectedBranchName, selectedServiceName, nextStep, backStep }) => {
  const { t } = useTranslation();
  const [tempDate, setTempDate] = useState(null); // Temporarily selected date
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false); // Confirm modal state
  const today = new Date();
  const tenDaysLater = addDays(today, 2); 

  const handleDateClick = (date) => {
    console.log("Date", date)
    setTempDate(date);
    setConfirmModalOpen(true); // Open confirm modal
  };

  const handleConfirm = () => {
    setSelectedDate(tempDate); // Set the confirmed date
    setConfirmModalOpen(false); // Close the modal
    nextStep(); // Proceed to the next step
  };

  const handleCancel = () => {
    setTempDate(null); // Clear the temporary date
    setConfirmModalOpen(false); // Close the modal
  };

  // Define the limits for month navigation
  const fromMonth = subMonths(today, 0);
  const toMonth = addMonths(today, 1);

  return (
    <div className="">
      <div className="flex px-2 justify-center align-middle text-center mt-2 bg-white border-gray-200 border rounded-xl py-2">
        <FaBuildingColumns className="w-10 h-10 text-gray-500 mr-4" />
        <div className="flex flex-col justify-start">
          <h2 className="font-semibold ml-2 line-clamp-2 text-lg text-gray-800">
            {t(selectedBranchName)} {t("branch")}
          </h2>
          <p className="font-normal ml-2 line-clamp-2 text-sm text-gray-800">
            {t("service")}: {t(selectedServiceName)}
          </p>
        </div>
      </div>
      <div className="bg-white border p-2 m-2 rounded-lg border-gray-300 text-gray-900 text-sm">
        <h2 className="text-pretty text-sm md:text-lg pb-2 text-gray-600 font-semibold">
          {t("selectDate")}
        </h2>

        <DayPicker
          mode="single"
          selected={tempDate || selectedDate}
          onSelect={handleDateClick}
          startMonth={fromMonth}
          endMonth={toMonth}
          disabled={[
            { before: today }, 
            { after: tenDaysLater }, 
            { dayOfWeek: [0] }, 
          ]}
          modifiersClassNames={{
            selected: 'bg-secondary text-white rounded-md shadow-sm',
            default: "bg-white rounded-md shadow-sm",
            today: 'bg-gray-200 rounded-md shadow-sm ',
            disabled: 'text-gray-300 cursor-not-allowed shadow-sm',
            future: "text-gray-500 cursor-not-allowed shadow-md",
          }}
          className="border-gray-300 p-2 border text-gray-800 text-sm rounded-md transition-all duration-300 ease-in-out"
        />
      </div>

      {isConfirmModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 max-w-sm">
            <h2 className="text-lg font-semibold text-gray-800 mb-4">{t("confirmDate")}</h2>
            <p className="text-gray-600 mb-2 font-semibold">
              {t('selectedDate')}: {tempDate && formatTime(tempDate)}
            </p>
            <p className="text-gray-600 mb-4 font-semibold">
              {t('ethiopianDate')}: {tempDate && convertToEthiopianDate(tempDate)}
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={handleCancel}
                className="bg-gray-400 text-white px-4 py-2 rounded-lg shadow-md hover:bg-gray-500 transition duration-300 ease-in-out"
              >
                {t('cancel')}
              </button>
              <button
                onClick={handleConfirm}
                className="bg-blue-600 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-500 transition duration-300 ease-in-out"
              >
                {t('next')}
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="flex w-full justify-around mt-4">
        <button
          onClick={backStep}
          className="bg-gray-400 text-white px-4 py-2 rounded-lg shadow-md hover:bg-gray-500 transition duration-300 ease-in-out"
        >
          {t('back')}
        </button>
      </div>
    </div>
  );
};

export default DateSelection;
