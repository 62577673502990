import React, { useState, useContext } from "react";
import "../../index.css";
import "react-calendar/dist/Calendar.css";
import Footer from "../Footer/SimpleFooter";
import Header from "../../Components/Header/QeteroHeader";
import { AuthContext } from "../../contexts/authContext";

function Profile() {
  const { user ,setUser,setToken} = useContext(AuthContext);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    firstname: user?.firstname || "",
    lastname: user?.lastname || "",
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleUpdate = async () => {
    if (!user) return;
    setLoading(true);
    setMessage("");

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/users/${user.id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData), // Only firstname and lastname are updated
      });

      if (response.ok) {
        const token = await response.json();
        setMessage("Profile updated successfully!");
        //update firstname and lastname on the context
        setUser(
          {
            ...user,
            firstname: formData.firstname,
            lastname: formData.lastname,
          }
        )
        //update firstname and lastname on localstorage 
        localStorage.setItem('user', JSON.stringify({...user, firstname: formData.firstname, lastname: formData.lastname}));
        //update the token on localstorage and setToken
        localStorage.setItem('token', JSON.stringify(token));
        setToken(token);
        setIsEditing(false);
      } else {
        setMessage("Failed to update profile. Please try again.");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      setMessage("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Header />
      <div className="flex flex-col items-center px-5 text-stone-900 mt-12">
        <div className="flex flex-col lg:flex-row w-full max-w-5xl mt-10 bg-white shadow-lg rounded-lg p-8">
          <div className="lg:w-1/3 flex flex-col items-center border-b lg:border-b-0 lg:border-r pb-8 lg:pb-0 lg:pr-8">
            <div className="w-32 h-32 bg-gray-300 rounded-full mb-4 flex justify-center items-center overflow-hidden">
              <img
                src="https://via.placeholder.com/150"
                alt="Profile"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="text-3xl font-bold text-center text-gray-800">
              {formData.firstname} {formData.lastname}
            </div>
            <p className="text-lg text-gray-600 mt-2 text-center">
              {user?.active ? "Active" : "Inactive"} User
            </p>
          </div>

          <div className="lg:w-2/3 flex flex-col justify-center pl-0 lg:pl-10 pt-6 lg:pt-0">
            <div className="w-full max-w-lg bg-gray-50 shadow-sm rounded-lg p-8">
              <div className="mb-6">
                <label
                  htmlFor="firstname"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  First Name:
                </label>
                <input
                  type="text"
                  id="firstname"
                  name="firstname"
                  value={formData.firstname}
                  onChange={handleChange}
                  readOnly={!isEditing}
                  className={`border ${
                    isEditing ? "border-blue-500" : "border-gray-300"
                  } rounded-lg w-full py-3 px-4 text-gray-700 focus:outline-none`}
                />
              </div>

              <div className="mb-6">
                <label
                  htmlFor="lastname"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Last Name:
                </label>
                <input
                  type="text"
                  id="lastname"
                  name="lastname"
                  value={formData.lastname}
                  onChange={handleChange}
                  readOnly={!isEditing}
                  className={`border ${
                    isEditing ? "border-blue-500" : "border-gray-300"
                  } rounded-lg w-full py-3 px-4 text-gray-700 focus:outline-none`}
                />
              </div>

              <div className="mb-6">
                <label
                  htmlFor="mobile"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Phone Number:
                </label>
                <input
                  type="tel"
                  id="mobile"
                  name="mobile"
                  value={user?.mobile || ""}
                  readOnly
                  className="border border-gray-300 rounded-lg w-full py-3 px-4 text-gray-700"
                />
              </div>

              <div className="flex justify-end">
                {!isEditing ? (
                  <button
                    className="bg-blue-500 text-white px-6 py-3 rounded-lg"
                    onClick={() => setIsEditing(true)}
                  >
                    Edit
                  </button>
                ) : (
                  <button
                    className={`${
                      loading ? "bg-gray-400" : "bg-green-500"
                    } text-white px-6 py-3 rounded-lg`}
                    onClick={handleUpdate}
                    disabled={loading}
                  >
                    {loading ? "Saving..." : "Save"}
                  </button>
                )}
              </div>

              {message && (
                <p
                  className={`mt-4 text-center ${
                    message.includes("successfully")
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  {message}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Profile;
