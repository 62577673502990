import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { QRCodeCanvas } from 'qrcode.react';
import Header from '../Header/QeteroHeader';
import SimpleFooter from '../Footer/SimpleFooter';
import { formatAppointmentTime, formatLocalTime, formatToTimeOnly } from '../../utils/convertTime';

const fetchAppointmentDetails = async (cnr) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/payments/appointment/${cnr}`);
  if (!response.ok) throw new Error("Failed to fetch appointment details");
  return response.json();
};

const SuccessMessage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const cnr = searchParams.get('merch_order_id');

  const { data, isLoading, error } = useQuery(
{
  queryKey: ['appointmentDetails', cnr],
  queryFn: () => fetchAppointmentDetails(cnr),
  enabled: !!cnr,
}
  );

  // console.log(data);

  return (
    <div className="min-h-screen flex flex-col">
      <Header  />
      {
        isLoading && (
          <div className="flex flex-col items-center justify-center h-screen">
            <div className="animate-pulse rounded-full h-20 w-20 bg-gray-300"></div>
            <p className="mt-4 text-gray-500">{t('Loading')}</p>
          </div>
        )
      }
      {
        error && (
          <div className="flex flex-col items-center justify-center h-screen">
            <p className="text-red-500">{t('Error fetching appointment details')}</p>
          </div>
        )
      }
{   data &&   (
      <div className="flex flex-col items-center px-2 mt-10 max-w-3xl mx-auto text-stone-900">
        <div className="w-full bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="flex flex-col md:flex-row">

            {/* Right Section with Appointment Details */}
            <div className="flex-1 p-10 flex flex-col justify-start ">
            <img src="./qetero_logo_text_3.svg" alt="Qetero" className="w-32 h-12 mb-2 self-center" />
              {data ? (
                <div className="text-base">
                  <div className="flex items-center mb-2">
                    <span className="mr-2 text-gray-900 font-bold">{t("booked_by")}:</span> 
                    <span>{data['user.firstname']} {data['user.lastname']}</span>
                  </div>
                  <div className="flex items-center mb-2">
                    <span className="mr-2 text-gray-900 font-bold">CNR:</span> 
                    <span>{data.cnr}</span>
                  </div>
                  <div className="flex items-center mb-2">
                    <span className="mr-2 text-gray-900 font-bold">{t("service")}:</span> 
                    <span>{data['branch_service.service.name']}</span>
                  </div>
                  <div className="flex items-center mb-2">
                    <span className="mr-2 text-gray-900 font-bold">{t("branch")}:</span> 
                    <span>{data['branch_service.branch.name']}</span>
                  </div>
                  <div className="flex items-center mb-2">
                    <span className="mr-2 text-gray-900 font-bold">{t("bookingDate")}:</span> 
                    <span>{formatAppointmentTime(data.appointment_time)}</span>
                  </div>
                  <div className="bg-gray-100 p-4 rounded-lg mb-6">
                    <h3 className="font-bold text-lg mb-2">{t("appointmentPolicy")}</h3>
                    <ul className="list-disc list-inside text-gray-700">
                      <li style={{ fontSize: 12 }}>{t("appointmentsNonRefundable")}</li>
                      <li style={{ fontSize: 12 }}>
                        {t("appointmentsNonTransferable")}
                      </li>
                      <li style={{ fontSize: 12 }}>{t("validForSpecificService")}</li>
                      <li style={{ fontSize: 12 }}>{t("oneServicePerAppointment")}</li>
                      <li style={{ fontSize: 12 }}>{t("validIDRequired")}</li>
                      <li style={{ fontSize: 12 }}>{t("mustArriveOnTime")}</li>
                      <li style={{ fontSize: 12 }}>{t("latePolicy")}</li>
                    </ul>
                  </div>                  
                  <button
                    className="bg-blue-700 text-white text-lg font-bold py-2 px-4 rounded w-full mt-4"
                    onClick={() => navigate('/bookingHistory')}
                  >
                    {t('View Booking History')}
                  </button>
                </div>
              ) : (
                <p className="text-xl text-stone-500">{t('Order number not available')}</p>
              )}
            </div>
          </div>
        </div>
      </div>)
      }
      <SimpleFooter />
    </div>
  );
};

export default SuccessMessage;
