export const servicesData = [
  {
    mainGroup: "Sales",
    subGroups: [
      {
        name: "Resident Sales",
        id: "resident-sales",
        documents: [
          "Identification of parties and witnesses",
          "Proof of seller’s authority to sell",
          "Marriage certificate or spousal consent if married",
          "Ownership certificate or property map for the seller",
          "Relevant legal documents for business entities",
        ],
      },
      {
        name: "Vehicle Sales",
        id: "vehicle-sales",
        documents: [
          "Ownership certificate (Libreta)",
          "Identification of seller and buyer",
          "Legal proof of authority to sell",
          "Vehicle valuation from transport authority",
          "Customs clearance if applicable",
        ],
      },
      {
        name: "Business Sales",
        id: "business-sales",
        documents: [
          "Main business registration and license",
          "Marriage certificate or spousal consent if married",
          "Tax clearance certificate",
          "Proof of ownership",
          "Founding and operational rules for business entities",
        ],
      },
      {
        name: "Vehicle Spare Part Sales",
        id: "vehicle-spare-part-sales",
        documents: [
          "Ownership certificate (Libreta)",
          "Identification of seller and buyer",
          "Legal proof of authority to sell",
          "Vehicle valuation from transport authority",
          "Customs clearance if applicable",
          "Proof of business registration if applicable",
          "Legal documents for vehicles under business ownership",
          "Marriage certificate or spousal consent if married",
        ],
      },
      {
        name: "Motorcycle Sales",
        id: "motorcycle-sales",
        documents: [
          "Ownership certificate (Libreta)",
          "Identification of seller and buyer",
          "Legal proof of authority to sell",
          "Vehicle valuation from transport authority",
          "Customs clearance if applicable",
          "Proof of business registration if applicable",
          "Legal documents for motorcycles under business ownership",
          "Marriage certificate or spousal consent if married",
        ],
      },
      {
        name: "Construction Machineries Sales",
        id: "construction-machinery-sales",
        documents: [
          "Ownership certificate (Libreta)",
          "Identification of seller and buyer",
          "Legal proof of authority to sell",
          "Vehicle valuation from transport authority",
          "Customs clearance if applicable",
          "Proof of business registration if applicable",
          "Legal documents for construction machinery under business ownership",
          "Marriage certificate or spousal consent if married",
        ],
      },
      {
        name: "Lease Sales",
        id: "lease-sales",
        documents: [
          "Identification of parties and witnesses",
          "Seller/lessor’s legal authority to lease",
          "Proof of property ownership or lease rights",
          "Business registration documents for lessor",
          "Marriage certificate or spousal consent if married",
        ],
      },
      {
        name: "Other Property Sales",
        id: "other-property-sales",
        documents: [
          "Identification of parties and witnesses",
          "Seller’s legal authority to sell property",
          "Proof of property ownership or lease rights",
          "Business registration documents for seller if applicable",
          "Marriage certificate or spousal consent if married",
        ],
      },
    ],
  },
  {
    mainGroup: "Gifts",
    subGroups: [
      {
        name: "Vehicle Gifts",
        id: "vehicle-gifts",
        documents: [
          "Ownership certificate (Libreta)",
          "Identification of seller and buyer",
          "Legal proof of authority to sell",
          "Vehicle valuation from transport authority",
          "Customs clearance if applicable",
          "Proof of business registration if applicable",
          "Legal documents for vehicles under business ownership",
          "Marriage certificate or spousal consent if married",
        ],
      },
      {
        name: "Residence Gifts",
        id: "residence-gifts",
        documents: [
          "Identification of parties and witnesses",
          "Legal proof of authority to sell",
          "Marriage certificate or spousal consent if married",
          "Proof of property ownership or lease rights",
          "Business registration documents for seller if applicable",
        ],
      },
      {
        name: "Business Gifts",
        id: "business-gifts",
        documents: [
          "Main business registration and license",
          "Marriage certificate or spousal consent if married",
          "Tax clearance certificate",
          "Proof of ownership",
          "Founding and operational rules for business entities",
        ],
      },
      {
        name: "Other Property Gifts",
        id: "other-property-gifts",
        documents: [
          "Identification of parties and witnesses",
          "Seller’s legal authority to sell property",
          "Proof of property ownership or lease rights",
          "Business registration documents for seller if applicable",
          "Marriage certificate or spousal consent if married",
        ],
      },
      {
        name: "Vehicle Spare Part Gifts",
        id: "vehicle-spare-part-gifts",
        documents: [
          "Ownership certificate (Libreta)",
          "Identification of seller and buyer",
          "Legal proof of authority to sell",
          "Vehicle valuation from transport authority",
          "Customs clearance if applicable",
          "Proof of business registration if applicable",
          "Legal documents for vehicles under business ownership",
          "Marriage certificate or spousal consent if married",
        ],
      },
      {
        name: "Motorcycle Gifts",
        id: "motorcycle-gifts",
        documents: [
          "Ownership certificate (Libreta)",
          "Identification of seller and buyer",
          "Legal proof of authority to sell",
          "Vehicle valuation from transport authority",
          "Customs clearance if applicable",
          "Proof of business registration if applicable",
          "Legal documents for motorcycles under business ownership",
          "Marriage certificate or spousal consent if married",
        ],
      },
      {
        name: "Construction Machineries Gifts",
        id: "construction-machinery-gifts",
        documents: [
          "Ownership certificate (Libreta)",
          "Identification of seller and buyer",
          "Legal proof of authority to sell",
          "Vehicle valuation from transport authority",
          "Customs clearance if applicable",
          "Proof of business registration if applicable",
          "Legal documents for construction machinery under business ownership",
          "Marriage certificate or spousal consent if married",
        ],
      },
    ],
  },
  {
    mainGroup: "Representation",
    subGroups: [
      {
        name: "General Representation",
        id: "general-representation",
        documents: [
          "Identification of the representative(s)",
          "Business registration and license (if applicable)",
          "Corporate resolution or authorization letter from the entity",
          "Proof of property or rights ownership being represented",
          "Other relevant legal documents",
        ],
      },
      {
        name: "Full General Representation",
        id: "full-general-representation",
        documents: [
          "Identification of the representative(s)",
          "Business registration and license (if applicable)",
          "Corporate resolution or authorization letter from the entity",
          "Proof of property or rights ownership being represented",
          "Full authorization or power of attorney for representation",
          "Additional supporting documents relevant to the case",
        ],
      },
      {
        name: "Family Representation",
        id: "family-representation",
        documents: [
          "Identification of the representative(s)",
          "Family relationship proof (e.g., marriage certificate, birth certificates)",
          "Legal authorization or power of attorney for representation",
          "Proof of ownership or rights related to family matters",
          "Supporting documents for the family-related matter",
        ],
      },
    ],
  },
  {
    mainGroup: "Rental",
    subGroups: [
      {
        name: "Business Rental",
        id: "business-rental",
        documents: [
          "Identification of the parties and witnesses",
          "Seller/lessor’s legal authority to lease",
          "Proof of marriage certificate or spousal consent if applicable",
          "Ownership or map of the business property",
          "Business registration and operating documents for the business entity",
        ],
      },
      {
        name: "Resident Rental",
        id: "resident-rental",
        documents: [
          "Identification of the parties and witnesses",
          "Seller/lessor’s legal authority to lease",
          "Marriage certificate or spousal consent if married",
          "Ownership or property map",
          "Registration of the property if part of a business entity",
        ],
      },
      {
        name: "Vehicle Rental",
        id: "vehicle-rental",
        documents: [
          "Identification of the parties and witnesses",
          "Seller/lessor’s legal authority to lease",
          "Marriage certificate or spousal consent if married",
          "Ownership or vehicle map",
          "Business registration if applicable",
        ],
      },
      {
        name: "Construction Machineries Rental",
        id: "construction-machinery-rental",
        documents: [
          "Identification of the parties and witnesses",
          "Seller/lessor’s legal authority to lease",
          "Marriage certificate or spousal consent if married",
          "Ownership or property map of machinery",
          "Business registration if applicable",
        ],
      },
    ],
  },
];

export const serviceDataForBH = [
  {
    mainGroup: "PLC",
    subGroups: [
      {
        name: "PLC",
        id: "plc",
        documents: [
          "Identification of parties and witnesses",
          "Proof of seller’s authority to sell",
          "Marriage certificate or spousal consent if married",
          "Ownership certificate or property map for the seller",
          "Relevant legal documents for business entities",
        ],
      },
    ],
  },
  {
    mainGroup: "Sales",
    subGroups: [
      {
        name: "Resident Sales",
        id: "resident-sales",
        documents: [
          "Identification of parties and witnesses",
          "Proof of seller’s authority to sell",
          "Marriage certificate or spousal consent if married",
          "Ownership certificate or property map for the seller",
          "Relevant legal documents for business entities",
        ],
      },
    ],
  },
  {
    mainGroup: "Gift",
    subGroups: [
      {
        name: "Vehicle Gifts",
        id: "vehicle-gifts",
        documents: [
          "Ownership certificate (Libreta)",
          "Identification of seller and buyer",
          "Legal proof of authority to sell",
          "Vehicle valuation from transport authority",
          "Customs clearance if applicable",
          "Proof of business registration if applicable",
          "Legal documents for vehicles under business ownership",
          "Marriage certificate or spousal consent if married",
        ],
      },
    ],
  },
  {
    mainGroup: "Representation",
    subGroups: [
      {
        name: "General Representation",
        id: "general-representation",
        documents: [
          "Identification of the representative(s)",
          "Business registration and license (if applicable)",
          "Corporate resolution or authorization letter from the entity",
          "Proof of property or rights ownership being represented",
          "Other relevant legal documents",
        ],
      },
    ],
  },
  {
    mainGroup: "Rental",
    subGroups: [
      {
        name: "Business Rental",
        id: "business-rental",
        documents: [
          "Identification of the parties and witnesses",
          "Seller/lessor’s legal authority to lease",
          "Proof of marriage certificate or spousal consent if applicable",
          "Ownership or map of service",
          "Business registration if applicable",
        ],
      },
    ],
  },
];
