// ServiceDocuments.js

import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../Components/Header/QeteroHeader";
import { servicesData } from "./servicesData"
import { useTranslation } from "react-i18next";

const ServiceDocuments = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();


  const [selectedMainGroup, setSelectedMainGroup] = useState(servicesData[0]);
  const [selectedSubGroup, setSelectedSubGroup] = useState(servicesData[0].subGroups[0]);
  const [openGroups, setOpenGroups] = useState({}); 


  useEffect(() => {
    const hash = location.hash.replace("#", ""); 
    let found = false;

    for (const group of servicesData) {
      const matchedSubGroup = group.subGroups.find((subGroup) => subGroup.id === hash);
      if (matchedSubGroup) {
        setSelectedMainGroup(group);
        setSelectedSubGroup(matchedSubGroup);
        found = true;
        break;
      }
    }

    if (!found) {
      navigate(`/required#${servicesData[0].subGroups[0].id}`);
    }
  }, [location.hash, navigate]);
  const toggleGroup = (groupName) => {
    setOpenGroups((prev) => ({
      ...prev,
      [groupName]: !prev[groupName],
    }));
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      <Header />
      <div className="max-w-6xl mx-auto p-8">
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="flex flex-col md:flex-row">
            <div className="bg-gradient-to-b from-blue-500 to-blue-700 text-white w-full md:w-1/3 p-6">
              <h2 className="text-2xl font-bold mb-6">{t("services")}</h2>
              <div className="space-y-6">
                {servicesData.map((group) => (
                  <div key={group.mainGroup}>
                    <h3
                      className="text-xl font-semibold mb-3 cursor-pointer"
                      onClick={() => toggleGroup(group.mainGroup)}
                    >
                      {t(`${group.mainGroup}`)}
                    </h3>

                    {/* Subgroup Dropdown */}
                    {openGroups[group.mainGroup] && (
                      <ul className="space-y-2 pl-4">
                        {group.subGroups.map((subGroup) => (
                          <li
                            key={subGroup.id}
                            onClick={() => navigate(`/required#${subGroup.id}`)}
                            className={`cursor-pointer px-3 py-2 rounded-lg text-lg transition-all duration-300 ${
                              selectedSubGroup.id === subGroup.id
                                ? "bg-blue-800"
                                : "hover:bg-blue-600"
                            }`}
                          >
                            {t(`${subGroup.name}`)}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
              </div>
            </div>

            {/* Main Content */}
            <div className="p-8 w-full md:w-2/3">
              <h2 className="text-4xl font-extrabold text-gray-800 mb-6">
                {t(`${selectedSubGroup.name}`)}
              </h2>
              <p className="text-lg text-gray-600 mb-6">
                {t('Here are the documents required for')} <strong>{t(`${selectedSubGroup.name}`)}</strong>:
              </p>
              <ul className="list-disc pl-6 space-y-4">
                {selectedSubGroup.documents.map((doc, index) => (
                  <li key={index} className="text-lg text-gray-700">
                    {t(`${doc}`)}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDocuments;
