import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        home: "Home",
        aboutUs: "About Us",
        contactUs: "Contact Us",
        profile: "Profile",
        bookingHistory: "Booking History",
        login: "Login",
        logout: "Sign out",
        welcome_to_qetero: "Welcome to Qetero",
        "Welcome_to_Documents_Authentication_and_Registration_Service_(DARS)_at_Qetero.com":"Welcome to Documents Authentication and Registration Service (DARS)",
        selectLocation: "Select Location",
        selectService: "Select Service",
        selectDate: "Select Date",
        AvailableTimes: "Available Times",
        Fetchingavailabletimeslots: "Fetching available time slots...",
        save_time_and_book_now: "Save Time & Book Now",
        confirm_appointment: "Confirm Appointment",
        service_type: "Service Type",
        scheduled_for: "Scheduled For",
        booked_by: "Booked By",
        location: "Location",
        payment_summary: "Payment Summary",
        total_appointment_fee: "Total Appointment Fee",
        pay_now: "Pay Now",
        request_cancellation: "Request Cancellation",
        dars_application_no: "DARS Application No",
        selectBranch: "Select Branch",
        selectService: "Select Service",
        selectDate: "Select Date",
        selectTimeSlot: "Select Time Slot",
        next: "Next",
        bookAppointment: "Book Appointment",
        cancel: "Cancel",
        morning: "Morning",
        afternoon: "Afternoon",
        slot: "slot",
        slots: "slots",
        branch: "Branch",
        service: "Service",
        date: "Date",
        time: "Time",
        select: "Select",
        "selam":"Selam",
          "whoWeAre": "Who We Are",
          "aboutUsDescription": "Welcome to Qetero, the ultimate online booking system designed to streamline your scheduling needs. Our platform is built with efficiency, ease of use, and customer satisfaction in mind, ensuring a smooth experience for both service providers and clients.",
          "mission": "Our Mission",
          "missionDescription": "At Qetero, our mission is to provide a seamless and efficient online booking experience, allowing users to manage their schedules effortlessly while maximizing convenience and accessibility.",
          "vision": "Our Vision",
          "visionDescription": "Our vision is to become a leader in online booking solutions, recognized for our innovation, reliability, and exceptional customer service. We aim to revolutionize the way individuals and businesses schedule their appointments.",
          "coreValues": "Core Values",
          "coreValuesDescription": "We prioritize the needs of our customers, fostering a culture of reliability, integrity, and continuous improvement. Our commitment to excellence drives us to innovate and adapt to the evolving demands of the industry.",              
        searchService: "Search Service",
        searchBranch: "Search Branch",
        darsBranches: "DARS branches",
        showMore: 'Show More',
        showLess: 'Show Less',
        select: "Select",
        "Megenagna (Branch 3)": "Megenagna (Branch 3)",
        "Meskel Square":"Meskel Square",
        "Bole Bulala (Branch 16)":"Bole Bulala (Branch 16)",
        "Lebu Maberat Haile (Branch 15)":"Lebu Maberat Haile (Branch 15)",
        "Jemo 1 Condominium (Branch 14)":"Jemo 1 Condominium (Branch 14)",
        "Summit Adebabay (Branch 13)":"Summit Adebabay (Branch 13)",
        "Bole Medhanialem (Branch 12)":"Bole Medhanialem (Branch 12)",
        "Qera (Branch 6)":"Qera (Branch 6)",
        "Kasanchis - Bambis (Branch 8)":"Kasanchis - Bambis (Branch 8)",
        "Kality":"Kality",
        "Ayer Tena Adebabay (Branch 6)":"Ayer Tena Adebabay (Branch 6)",
        "4 Kilo (Branch 4)":"4 Kilo (Branch 4)",
        "6 Kilo (Branch 16)": "6 Kilo (Branch 16)",
        "Merkato- Ihel Berenda":"Merkato- Ihel Berenda",
        "Global Lancha (Branch 1)":"Global Lancha (Branch 1)",
        "Dire Dawa":"Dire Dawa",
        "Ledeta":"Ledeta",
        "Plc Services":"Plc Services",
        "Sales Services": "Sales Services",
        "Gift Services": "Gift Services",
        "Rental Services":"Rental Services",
        "Loan Services":"Loan Services",
        "Representation Services":"Representation Services",
        "enjoyFreeService": "Skip the long queues and save time with Qetero! Book your appointment for free now before booking fees start soon.",
        "freeForNow": "Free for now!",
        "stayTuned": "Stay tuned for the next appointment.",
        "processing": "Processing...",
        "loading": "Loading...",
        "back":"Back",
        "enterPhoneNumber": "Enter your Phone Number",
        "phoneNumber": "Phone Number",
        "sendVerificationCode": "Send Verification Code",
        "sending": "Sending...",
        "verificationCodeSent": "Verification code sent successfully!",
        "book_now": "Book Now",
        "appointmentBooked": "Appointment Booked!",
        "receipt": "Receipt",
        "appointmentTime": "Appointment Time",
        "scanQRCode": "Scan the QR code to access your appointment details.",
        "close": "Close",
        "enterOtp": "Enter OTP",
        "otp": "OTP",
        "verify": "Verify",
        "otpVerified": "OTP verified successfully!",
        "enterPhoneNumber": "Enter your Phone Number",
        "phoneNumber": "Phone Number",
        "sendVerificationCode": "Send Verification Code",
        "sending": "Sending...",
        "verificationCodeSent": "Verification code sent successfully!",
        "enterOtp": "Enter OTP",
        "otp": "OTP",
        "verify": "Verify",
        "otpVerified": "OTP verified successfully!",
        "paymentSuccess": "Payment Successful!",
        "paymentFailed": "Payment Failed!",
        "tryAgain": "Try Again",
        "appointmentCanceled": "Appointment Canceled!",
        "appointmentCanceledSuccessfully": "Appointment Canceled Successfully!",
        "cancelAppointment": "Cancel Appointment",
        "cancel": "Cancel",
        "confirm": "Confirm",
        "downloadReceipt": "Download Receipt",
        "download": "Download",
        "Bole Bulala": "Bole Bulala",
        "Kasanchis - Bambis": "Kasanchis - Bambis",
        "Qera": "Qera",
        "Summit Adebabay": "Summit Adebabay",
        "Ayer Tena Adebabay": "Ayer Tena Adebabay",
        "4 Kilo": "4 Kilo",
        "Lebu Maberat Haile": "Lebu Maberat Haile",
        "Bole Medhanialem": "Bole Medhanialem",
        "Mexico": "Mexico",
        "Ihil Berenda":"Ihil Berenda",
        "6 Kilo": "6 Kilo",
        "Global Lancha": "Global Lancha",
        "Merkato- Ihel Berenda": "Merkato- Ihel Berenda",
        "Jemo 1 Condominium": "Jemo 1 Condominium",
        "Kality": "Kality",
        "Megenagna": "Megenagna",
        "Dire Dawa": "Dire Dawa",
        "Meskel Square": "Meskel Square",
        "Chelelek Alsam Tower - 2nd Floor": "Chelelek Alsam Tower - 2nd Floor",
        "Jemo 1": "Jemo 1",
        "Ayer Tena":"Ayer Tena",
        "Bole":"Bole",
        "Summit":"Summit",
        "Kasanchis":"Kasanchis",
        "Merkato":"Merkato",
        "Mesalemia":"Mesalemia",
        "Lebu":"Lebu",
        "Near to Ethio Tebib Hospital":"Near to Ethio Tebib Hospital",
        "Derartu Tower":"Derartu Tower",
        "Baleker Building":"Baleker Building",
        "freeForNow": "This service is free for now",
        "enjoyFreeService": "Enjoy this free service, normally valued at {{amount}}!",
        "stayTuned": "Stay tuned for more updates and offers.",
        "appointmentPolicy": "Appointment Policy Consent",
        "appointmentsNonRefundable": "Appointments are non-refundable.",
        "appointmentsNonTransferable": "Appointments cannot be transferred to another person.",
        "validForSpecificService": "The appointment is valid only for the specific service you have booked.",
        "oneServicePerAppointment": "You may only book one service per appointment.",
        "validIDRequired": "A valid ID must be presented at the time of the appointment.",
        "mustArriveOnTime": "You must arrive on time for your appointment.",
        "latePolicy": "If you are late, we reserve the right not to serve you.",
        "readAndAgree": "By proceeding, you confirm that you have read, understood, and agree to these terms.",
        "processing": "Processing...",
        "bookAppointment": "Book Appointment",
        "total": "Total",
        "upcoming": "Upcoming",
        "completed": "Completed",
        "search": "Search",
        "noBookingFound": "No bookings found",
        "view": "View",
        "status": "Status",
        "searchPlaceHolder": "Search by CNR...",
        "step": "Step",
        "easyStepsToRegister": "How to Register and Book an Appointment",
        "selectBranchFromOptions": "Select a branch from the available options.",
        "enterPhonenumberAndGetOTP": "Enter your phone number to receive an OTP for verification.",
        "verifyAndSelectService": "Once verified, choose the service you require.",
        "selectDateOfAppointment": "Select the date for your appointment.",
        "chooseTimeSlot": "Choose a time slot from the available options.",
        "clickOnBookAppointment": "Click on 'Book Appointment' to proceed.",
        "confirmAppointmentAndConfirm": "Confirm your appointment in the pop-up that appears by pressing 'Book Appointment'.",
        "enterTelebirrPinAndComplete": "Enter your Telebirr PIN and verify to complete the booking.",
        "termsAndConditions": "Terms and Conditions",
        "help": "Help",
        "agree": "Agree",
        "firstName": "First Name",
        "lastName": "Last Name",
        "signUp": "Sign Up",
        "createAnAccount": "Create an Account",
        "userAgreementToTerms": "You Must Agree to Terms and conditions.",
        "readTermsAndConditions": "You must atleast read the terms once.",
        "allFieldsAreReqired": "All Fields are required.",
        "verifyPhoneNumber": "Mobile Phone Verification",
        "enterOtpOfFiveDigit":"Enter the 5-digit verification code that was sent to +251",
        "didntReceivePin": "Didn't receive code?",
        "resendIn": "Resend in",
        "VIP-Megenagna":"VIP-Megenagna",
        "seconds": "seconds",
        "verifyAccount": "Verify Account",
        "verifying": "Verifying",
        "resendAgain": "Resend Code",
        "confirmDate": "Confirm Date?",
        "selectedDate":"Selected date",
        "ethiopianDate":"Ethiopian date",
        pay_service_fee: "Pay Your Service Fee ",
        amount: "Amount: ",
        pay: "Pay",
        processing: "Processing...",
        bookingTime:"Booking Time",
        bookingDate: "Booking Date",
        serviceFee: "Total Service Fee",
        "Exciting news!": "ዜና!",
          'are_you_sure_you_want_to_confirm':"Are you sure you want to confirm your appointment at {{time}}?",
          "confirm_booking": "Confirm Booking",
          "noSubServicesAvailable": "No sub-services available",
          "errorLoadingSubServices": "Error loading sub-services",
          "whoWeAre": "Who We Are",
          "aboutUsDescription": "Welcome to Qetero, the ultimate online booking system designed to streamline your scheduling needs. Our platform is built with efficiency, ease of use, and customer satisfaction in mind, ensuring a smooth experience for both service providers and clients.",
          "mission": "Our Mission",
          "missionDescription": "At Qetero, our mission is to provide a seamless and efficient online booking experience, allowing users to manage their schedules effortlessly while maximizing convenience and accessibility.",
          "vision": "Our Vision",
          "visionDescription": "Our vision is to become a leader in online booking solutions, recognized for our innovation, reliability, and exceptional customer service. We aim to revolutionize the way individuals and businesses schedule their appointments.",
          "coreValues": "Core Values",
          "coreValuesDescription": "We prioritize the needs of our customers, fostering a culture of reliability, integrity, and continuous improvement. Our commitment to excellence drives us to innovate and adapt to the evolving demands of the industry.",
          "Exciting news!": "News!",
          "DARS branches will soon offer an online appointment booking system to streamline visits, reduce wait times, and enhance your experience. Stay tuned for updates!": "All self booking appointments are fully booked, please check back tomorrow",
          confirmAndPay:"Confirm and Pay",
        pay_with_telebirr: "Pay with telebirr",
          "services": "Services",
          'Debre Zeit Road Near Agona Cinema, Balkar Building': 'Debre Zeit Road Near Agona Cinema, Balkar Building',
          'Sefere selam Near Ethio-Tebib Hospital': 'Sefere selam Near Ethio-Tebib Hospital',
          'Megenagna Derartu Tulu Building': 'Megenagna Derartu Tulu Building',
          'Sidist Kilo around Egypt Embassy, International Leadership Institute, 2nd floor': 
            'Sidist Kilo around Egypt Embassy, International Leadership Institute, 2nd floor',
          'Around Ethiopian News Agency, Darele Building': 
            'Around Ethiopian News Agency, Darele Building',
          'Around Ayer Tena Zewditu Building': 'Around Ayer Tena Zewditu Building',
          'In Front of Kality Prison, Sheger Building 2nd floor': 
            'In Front of Kality Prison, Sheger Building 2nd floor',
          'Kazanchis In Front of Yordanos Hotel, Enat Building, 3rd Floor': 
            'Kazanchis In Front of Yordanos Hotel, Enat Building, 3rd Floor',
          'Nefas Silk Lafto Sub City, Road From Anbessa Shoe to Kirkos Church, SD Building, 2nd Floor': 
            'Nefas Silk Lafto Sub City, Road From Anbessa Shoe to Kirkos Church, SD Building, 2nd Floor',
          'Near Mexico Square In front of Philips Building, “Chelelek Aslam Tower” 2nd Floor': 
            'Near Mexico Square In front of Philips Building, “Chelelek Aslam Tower” 2nd Floor',
          'CMC Square Road that takes to Semit in front of Sun Shine, Building that hold CBE Semit Branch 1st floor': 
            'CMC Square Road that takes to Semit in front of Sun Shine, Building that hold CBE Semit Branch 1st floor',
          'Near Mafi City Center, Dr Beyen Building 3rd floor': 
            'Near Mafi City Center, Dr Beyen Building 3rd floor',
          'Merkato Gojam Berenda': 'Merkato Gojam Berenda',
          'Jemo 1 Delina 3rd Floor': 'Jemo 1 Delina 3rd Floor',
          'Dire Dawa Management Council Building': 
            'Dire Dawa Management Council Building',
            "Mesalemiya": 'Mesalemiya',
            'Sidist Kilo': 'Sidist Kilo',
            'Lancha': 'Lancha',
            "no_afternoon_slots":"No Afternoon Slots",
            "no_morning_slots":"No Morning Slots"
    
        },
    },
    am: {
      translation: {
        home: "ዋና ገጽ",
        aboutUs: "ስለ እኛ",
        contactUs: "እኛን ያግኙ",
        profile: "መገለጫ",
        bookingHistory: "የቀጠሮ ታሪክ",
        login: "ግባ",
        logout: "ውጣ",
        welcome_to_qetero: "እንኳን ወደ ቀጠሮ በደህና መጡ",
        selectLocation: "ቦታ ይምረጡ",
        selectService: "አገልግሎት ይምረጡ",
        selectDate: "ቀን ይምረጡ",
        AvailableTimes: "የሚገኙ ሰዓቶች",
        Fetchingavailabletimeslots: "የሚገኙ ሰዓቶች እየተጫኑ ነው...",
        save_time_and_book_now: "ጊዜዎን ይቆጥቡ እና አሁን ይያዙ",
        confirm_appointment: "ቀጠሮዎን ያረጋግጡ",
        service_type: "የአገልግሎት አይነት",
        scheduled_for: "ለተያዘው",
        booked_by:"የተያዘው በ",
        location: "አድራሻ",
        payment_summary: "የክፍያ ማጠቃለያ",
        total_appointment_fee: "የአጠቃላይ ቀጠሮ ክፍያ",
        pay_now: "አሁን ይክፈሉ",
        request_cancellation: "የመሰረዝ ጥያቄ ያቅርቡ",
        dars_application_no: "የDARS ማመልከቻ ቁጥር",
        selectBranch: "ቅርንጫፍ ይምረጡ ",
        selectService: "አገልግሎት ይምረጡ ",
        selectDate: "ቀን ይምረጡ",
        selectTimeSlot: "የጊዜ ክፍተት ይምረጡ",
        next: "ቀጣይ",
        bookAppointment: "ቀጠሮ ይያዙ",
        cancel: "ሰርዝ",
        morning: "ጠዋት",
        afternoon: "ከሰዓት",
        slot: "ቦታ",
        slots: "ቦታዎች",
        branch: "ቅርንጫፍ",
        service: "አገልግሎት",
        date: "ቀን",
        time: "ሰዓት",
        select: "ይምረጡ",
        searchService: "አገልግሎት ይፈልጉ",
        searchBranch: "ቅርንጫፍ ይፈልጉ",
        darsBranches: "የDARS ቅርንጫፎች",
        showMore: 'ተጨማሪ አሳይ',
        showLess: 'ያነሰ አሳይ',
        select: "ይምረጡ",
        "selam":"ሰላም",
        "Welcome_to_Documents_Authentication_and_Registration_Service_(DARS)_at_Qetero.com":"እንኳን በደህና መጡ ወደ የሰነዶች ማረጋገጫ እና ምዝገባ አገልግሎት (DARS)",
        "Megenagna (Branch 3)": "መገናኛ (ቅርንጫፍ 3)",
        "Ihil Berenda":"እህል በረንዳ",
        "Meskel Square":"መስቀል አደባባይ",
        "Bole Bulala (Branch 16)":"ቦሌ ቡልቡላ (ቅርንጫፍ 16)",
        "Lebu Maberat Haile (Branch 15)":"ለቡ መብራት ኃይሌ (ቅርንጫፍ 15)",
        "Jemo 1 Condominium (Branch 14)":"ጀሞ 1 ኮንዶሚኒየም (ቅርንጫፍ 14)",
        "Summit Adebabay (Branch 13)":"ሰሚት አደባባይ (ቅርንጫፍ 13)",
        "Bole Medhanialem (Branch 12)":"ቦሌ መድሐኒአለም (ቅርንጫፍ 12)",
        "Qera (Branch 6)":"ቄራ (ቅርንጫፍ 6)",
        "Kasanchis - Bambis (Branch 8)":"ካሳንቺስ - ባምቢስ (ቅርንጫፍ 8)",
        "Kality":"ቃሊቲ",
        "Ayer Tena Adebabay (Branch 6)":"አየር ተና አደባባይ (ቅርንጫፍ 6)",
        "4 Kilo (Branch 4)":"4 ኪሎ (ቅርንጫፍ 4)",
        "6 Kilo (Branch 16)": "6 ኪሎ (ቅርንጫፍ 16)",
        "Merkato- Ihel Berenda":"መርካቶ-እህል በረንዳ",
        "Global Lancha (Branch 1)":"ግሎባል ላንቻ (ቅርንጫፍ 1)",
        "Dire Dawa":"ድሬ ዳዋ",
        "Ledeta":"ልደታ",
        "Plc Services":"የPlc አገልግሎት",
        "Sales Services": "የሻጮች አገልግሎት",
        "Gift Services": "የስጦታ አገልግሎት",
        "Rental Services":"የኪራይ አገልግሎት",
        "Loan Services":"የብድር አገልግሎት",
        "Representation Services":"የውክልና አገልግሎት",
        "enjoyFreeService": "ረጅም መስመሮችን ይዝለሉ እና በቀጠሮ ጊዜዎን ይቆጥቡ! በቅርቡ የመያዣ ክፍያ ከመጀመሩ በፊት አሁን ነፃ ቀጠሮዎን ይያዙ።",
        "freeForNow": "ለአሁን ነፃ!",
        "stayTuned": "ለቀጣዩ ቀጠሮ ይጠብቁ።",
        "processing": "እየተሰራ ነው...",
        "loading": "እየተከናወነ ነው...",
        "back":"ወደ ኋላ",
        "enterPhoneNumber": "የስልክ ቁጥርዎን ያስገቡ",
        "phoneNumber": "የስልክ ቁጥር",
        "sendVerificationCode": "የማረጋገጫ ኮድ ይላኩ",
        "sending": "እየተላከ ነው...",
        "verificationCodeSent": "የማረጋገጫ ኮድ  ተልኳል!",
        book_now: "አሁን ይያዙ",
        "appointmentBooked": "ቀጠሮ ተይዟል!",
        "receipt": "ደረሰኝ",
        "appointmentTime": "የቀጠሮ ሰዓት",
        "scanQRCode": "የQR ኮዱን ለመቃኘት እና የቀጠሮ ዝርዝሮችዎን ለማግኘት ይጠቀሙ።",
        "close": "ዝጋ",
        "enterOtp": "OTP ያስገቡ",
        "otp": "OTP",
        "verify": "ያረጋግጡ",
        "otpVerified": "OTP በተሳካ ሁኔታ ተረጋግጧል!",
        "enterPhoneNumber": "የስልክ ቁጥርዎን ያስገቡ",
        "phoneNumber": "የስልክ ቁጥር",
        "sendVerificationCode": "የማረጋገጫ ኮድ ይላኩ",
        "sending": "እየተላከ ነው...",
        "verificationCodeSent": "የማረጋገጫ ኮድ  ተልኳል!",
        "enterOtp": "OTP ያስገቡ",
        "otp": "OTP",
        "verify": "ያረጋግጡ",
        "otpVerified": "OTP በተሳካ ሁኔታ ተረጋግጧል!",
        "paymentSuccess": "ክፍያ በተሳካ ሁኔታ ተጠናቀቀ!",
        "paymentFailed": "ክፍያ አልተሳካም!",
        "tryAgain": "እንደገና ይሞክሩ",
        "appointmentCanceled": "ቀጠሮ ተሰርዟል!",
        "appointmentCanceledSuccessfully": "ቀጠሮ በተሳካ ሁኔታ ተሰርዟል!",
        "cancelAppointment": "ቀጠሮ ይሰርዙ",
        "cancel": "ሰርዝ",
        "confirm": "አረጋግጥ",
        "downloadReceipt": "ደረሰኝ ያውርዱ",
        "download": "ያውርዱ",
        "downloadReceipt": "ደረሰኝ ያውርዱ",
        "download": "ያውርዱ",
        "Bole Bulala": "ቦሌ ቡላቡላ",
        "Kasanchis - Bambis": "ካሳንቺስ - ባምቢስ",
        "Qera": "ቄራ",
        "Summit Adebabay": "ሰሚት አደባባይ",
        "Ayer Tena Adebabay": "አየር ተና አደባባይ",
        "4 Kilo": "4 ኪሎ",
        "Lebu Maberat Haile": "ሌቡ መብራት ኃይሌ",
        "Bole Medhanialem": "ቦሌ መድሐኒአለም",
        "Mexico": "ሜክሲኮ",
        "6 Kilo": "6 ኪሎ",
        "Global Lancha": "ግሎባል ላንቻ",
        "Merkato- Ihel Berenda": "መርካቶ-እህል በረንዳ",
        "Jemo 1 Condominium": "ጀሞ 1 ኮንዶሚኒየም",
        "Kality": "ቃሊቲ",
        "Megenagna": "መገናኛ",
        "Dire Dawa": "ድሬ ዳዋ",
        "Meskel Square": "መስቀል አደባባይ",
        "Chelelek Alsam Tower - 2nd Floor": "ጨለለቅ አልሳም ታወር - 2ኛ ፎቅ",
        "Jemo": "ጀሞ",
        "Ayer Tena":"አየር ጤና",
        "Bole":"ቦሌ",
        "Bulbula":"ቡላቡላ",
        "6 kilo": "6 ኪሎ",
        "Ayat Adebabay": "አያት አደባባይ",
        "CMC":"ሲምሲ",
        "Summit":"ሰሚት",
        "Addisu gebeya":"አዲሱ ገበያ",
        "Kasanchis":"ካሳንቺስ",
        "Merkato":"መርካቶ",
        "Mesalemia":"መሳለሚያ",
        "Lebu":"ለቡ",
        "Near to Ethio Tebib Hospital":"ከኢትዮ ጤቢብ ሆስፒታል አጠገብ",
        "Derartu Tower":"ደራርቱ ታወር",
        "Baleker Building":"ባለከር ህንፃ",
          "freeForNow": "አሁን እንዲሁ ነፃ አገልግሎት ነው",
          "enjoyFreeService": "ይህንን ነፃ አገልግሎት ይደሰቱ፣ በተለመደ የመዋጮ ዋጋ ${{amount}} ነው!",
          "stayTuned": "ለተጨማሪ አዳዲስ እና ቅናሽ እንዲያውሩ ይጠብቁን።",
          "appointmentPolicy": "የቀጠሮ ፖሊሲ ማረጋገጫ",
          "appointmentsNonRefundable": "ቀጠሮዎች መመለስ አይችሉም።",
          "appointmentsNonTransferable": "ቀጠሮዎች ለሌላ ሰው ማስተላለፍ አይችሉም።",
          "validForSpecificService": "ቀጠሮው ለትክክለኛው አገልግሎት ብቻ ታስተካከላል።",
          "oneServicePerAppointment": "በአንድ ቀጠሮ ላይ አንድ አገልግሎት ብቻ ማስያዝ ይችላሉ።",
          "validIDRequired": "በቀጠሮ ጊዜ እንዲታዩ ትክክለኛ መታወቂያ እንደሚያስፈልግ።",
          "mustArriveOnTime": "በቀጠሮው ሰዓት መድረስ አለብዎት።",
          "latePolicy": "ሲደርሱ በመረገጫ ሰዓት ካወገዙ አገልግሎት መስጠት እንዳልን መብት አለን።",
          "readAndAgree": "ቀጠሮውን በመጠቀም፣ እነዚህን ሁሉ መርዳትን እንደማጽድቁ ታረጋግጣላችሁ።",
          "processing": "እየተሰራ ነው...",
          "bookAppointment": "ቀጠሮ ያስይዙ",
          "PLC": "የድርጅት",
          "Sales": "ሽያጭ",
          "Rental":"ከራይ",
          "Loan": "ብድር",
          "Representation": "ውክልና",
          "Gift":"ስጦታ",
        "Other": "ሌሎች",
        "total": "አጠቃላይ",
        "upcoming":"እየተቃረበ ያለ",
        "completed": "የተጠናቀቀ",
        "search": "ፈልግ",
        "noBookingFound": "ምንም የተያዘ ቀጠሮ የለም።",
        "view": "ዝርዝር ይመልከቱ",
        "status": "ያለበት ሁኔታ",
        "searchPlaceHolder": "ቂልፍ ቃል ያስገቡ...",
        "step": "እርምጃ",
        "easyStepsToRegister": "በቀላሉ ለመመዝገብ እና ቀጠሮ ለመያዝ ይህን ዝርዝር ይከተሉ።",
           "selectBranchFromOptions": "ካሉት ዝርዝሮቸ ውስጥ ቅርንጫፍ ይምረጡ።",
        "enterPhonenumberAndGetOTP": "ስልክ ቁጥሮን በማስገባት እራስዎን ያረጋግጡ።",
        "verifyAndSelectService": "የሚፈልጉትን አገልግሎት ይምረጡ።",
        "selectDateOfAppointment": "የቀጠሮ ቀኖን ይምረጡ።",
        "chooseTimeSlot": "የቀጠሮ ሰአትዎን ይምረጡ።",
        "clickOnBookAppointment": "ቀጠሮ ይያዙ የሚለውን ይጫኑ። ",
        "confirmAppointmentAndConfirm": "በሚመጣልዎት መግለጫ ሳጥን አረጋግጥ የሚለውን በመጫን ቀጠሮውን ያረጋግጡ።",
        "enterTelebirrPinAndComplete": "በመጫረሻም የቴሌብር ፒን በማስገባት ያረጋግጡ።",
        "termsAndConditions": "ውሎች እና ግዴታዎች",
        "help": "እርዳታ",
        "agree": "እስማማለው",
        "firstName": "ስም",
        "lastName": "የአባት ስም",
        "signUp": "ይመዝገቡ",
        "createAnAccount": "መለያ ይፍጠሩ።",
        "userAgreementToTerms": "ውሎች እና ግዴታዎች ጋር መስማማትዎን ያረጋግጡ።",
        "readTermsAndConditions": "ውል እና ግዴታዎችን ከፍተው ማንበቦን ያረጋግጡ።",
        "allFieldsAreReqired": "ሁኩንም ቅጽ ማስገብያዎች መሙላቶን ያረጋግጡ።",
        "verifyPhoneNumber": "ስልክ ቁጥሮን ያረጋግጡ።",
        "enterOtpOfFiveDigit": "ወደ ስልኮት የተላከውን ባለ 5 አሀዝ ቂጥር ያስገቡ፣ የተላከበት ስልክ ቁጥር +251",
        "didntReceivePin": "የተላከው ፒን ኮድ አልደረስዎትም?",
        "resendIn": "ድጋሚ ለመላክ",
        "resendAgain": "ድጋሚ ላክ።",
        "seconds": "ሴኮንዶች ይጠብቂ።",
        "verifyAccount": "መለያዎን ያረጋግጡ።",
        "verifying": "በማረጋገጥ ላይ",
           "Other":"ሌሎች",
           "confirmDate": "ቀኑን ያረጋግጡ?",
           "selectedDate":"የተመረጠው ቀን:",
           "ethiopianDate":"በኢትዮጵያ ቀን፡",
           pay_service_fee: "የአገልግሎት ክፍያ ",
           amount: "የብር መጠን: ",
           pay: "ይክፈሉ",
           processing: "ክፍያን በማካሄድ ላይ...",
           "Exciting news!": "ዜና!",
          "DARS branches will soon offer an online appointment booking system to streamline visits, reduce wait times, and enhance your experience. Stay tuned for updates!": "ሁሉም ቀጠሮዎች ሙሉ በሙሉ ተይዘዋል።እባክዎ ነገ ተመልሰው ያረጋግጡ!",
          'are_you_sure_you_want_to_confirm':"በ{{time}} ላይ ቀጠሮ ለመያዝ እርግጠኛ ነዎት?",
          "confirm_booking": "ቀጠሮ ማረጋገጥ",
          "noSubServicesAvailable": "ምንም የሚገኝ የአገልግሎት አይነት የለም",
          "errorLoadingSubServices": "የአገልግሎት አይነቶችን በመጫን ላይ ችግር ተፈጠረ",
          "whoWeAre": "እኛ ማን ነን?",
          "aboutUsDescription": "እንኳን ወደ ቀጠሮ በደህና መጡ፣ ለቀጠሮ ማስያዣ ፍላጎቶችዎ ቀላል የሆነ የመስመር ላይ ማስያዣ ስርዓት። መድረኩ ለአገልግሎት ሰጪዎች እና ለደንበኞች ለስላሳ ተሞክሮ ለማረጋገጥ በብቃት፣ በቀላል አጠቃቀም እና በደንበኛ እርካታ ላይ የተመሰረተ ነው።",
          "mission": "ተልዕኮአችን",
          "missionDescription": "በቀጠሮ፣ ተልዕኮአችን ለተጠቃሚዎች ቀጠሮዎቻቸውን በቀላሉ እንዲያስተዳድሩ እና ምቾት እና ተደራሽነትን እንዲያሳድጉ በማድረግ ለስላሳ እና ውጤታማ የመስመር ላይ የቀጠሮ ማስያዣ ተሞክሮ መስጠት ነው።",
          "vision": "ራዕያችን",
          "visionDescription": "ራዕያችን በፈጠራ፣ በአስተማማኝነት እና በልዩ ደንበኛ አገልግሎት የሚታወቅ የመስመር ላይ የቀጠሮ ማስያዣ መፍትሄዎች መሪ መሆን ነው። ግለሰቦች እና ንግዶች ቀጠሮዎቻቸውን የሚያስተዳድሩበትን መንገድ ለመቀየር እንፈልጋለን።",
          "coreValues": "ዋና ዋና እሴቶች",
          "coreValuesDescription": "የደንበኞቻችንን ፍላጎት ቅድሚያ እንሰጣለን፣ የአስተማማኝነት፣ የታማኝነት እና የማያቋርጥ ማሻሻያ ባህል እንፈጥራለን። ለልዕልና ያለን ቁርጠኝነት በኢንዱስትሪው እየተለወጡ ባሉ ፍላጎቶች ለመለወጥ እና ለማደስ ያነሳሳናል።",
          "Exciting news!": "ዜና!",          
          "bookingTime":"የቀጠሮ ሰዓት",
          "bookingDate": "የቀጠሮ ቀን",
          "serviceFee": "አጠቃላይ የአገልግሎት ክፍያ",
          "confirmAndPay": "አረጋግጥ  እና ይክፈሉ",
        pay_with_telebirr: "በቴሌብር ይክፈሉ",
        "services": "አገልግሎቶች",
        "Resident Sales": "የመኖርያ ቤት ሽያጭ",
        "Vehicle Sales": "የመኪና ቤት ሽያጭ",
        "Business Sales": "የንግድ ሽያጭ",
        "Vehicle Spare Part Sales": "የተሽከርካሪ መለዋወጫ ክፍል ሽያጭ",
        "Motorcycle Sales": "የሞተርሳይክል ሽያጭ",
        "Construction Machineries Sales": "የግንባታ ማሽኖች ሽያጭ",
        "Lease Sales": "የሊዝ ሽያጭ",
        "Other Property Sales": "የሌላ ንብረት ሽያጭ",
        "Gifts": "ስጦታዎች",
        "Vehicle Gifts": "የተሽከርካሪ ስጦታዎች",
        "Residence Gifts": "የመኖሪያ ቤት ስጦታዎች",
        "Business Gifts": "የንግድ ስጦታዎች",
        "Other Property Gifts": "ሌሎች የንብረት ስጦታዎች",
        "Vehicle Spare Part Gifts": "የተሽከርካሪ መለዋወጫ ክፍል ስጦታዎች",
        "Motorcycle Gifts": "የሞተርሳይክል ስጦታዎች",
        "Construction Machineries Gifts": "የግንባታ ማሽኖች ስጦታዎች",
        "General Representation": "አጠቃላይ ውክልና",
        "Full General Representation": "ሙሉ አጠቃላይ ውክልና",
        "Family Representation": "የቤተሰብ ውክልና",
        "Business Rental": "የንግድ ኪራይ",
        "Resident Rental": "የመኖሪያ ቤት ኪራይ",
        "Vehicle Rental": "የተሽከርካሪ ኪራይ",
        "Construction Machineries Rental": "የግንባታ ማሽኖች ኪራይ",
        "Here are the documents required for": "መያዝ ያለበት ሰነዶች",
        "Identification of parties and witnesses": "የፓርቲዎች እና ምስክሮች መታወቂያ",
        "Proof of seller’s authority to sell": "የሻጭ የመሸጥ ስልጣን ማረጋገጫ",
        "Marriage certificate or spousal consent if married": "ካገቡ የጋብቻ የምስክር ወረቀት ወይም የትዳር ስምምነት",
        "Ownership certificate or property map for the seller": "ለሻጩ የባለቤትነት የምስክር ወረቀት ወይም የንብረት ካርታ",
        "Relevant legal documents for business entities": "ለንግድ አካላት ተዛማጅ ህጋዊ ሰነዶች",
        "Ownership certificate (Libreta)": "የባለቤትነት የምስክር ወረቀት (ሊብሬታ)",
        "Identification of seller and buyer": "የሻጭ እና ገዢ መለያ",
        "Legal proof of authority to sell": "ለመሸጫ ስልጣን ህጋዊ ማረጋገጫ",
        "Vehicle valuation from transport authority": "የተሽከርካሪ ግምገማ ከትራንስፖርት ባለስልጣን",
        "Customs clearance if applicable": "አስፈላጊ ከሆነ የጉምሩክ ማረጋገጫ",
        "Main business registration and license":"ዋና የንግድ ምዝገባ እና ፈቃድ",
        "Tax clearance certificate": "የግብር ማረጋገጫ የምስክር ወረቀት",
        "Proof of ownership":"የባለቤትነት ማረጋገጫ",
        "Founding and operational rules for business entities": "ለንግድ አካላት መመስረቻ እና የአሠራር ህጎች",
        "Proof of business registration if applicable":"አስፈላጊ ከሆነ የንግድ ምዝገባ ማረጋገጫ",
        "Legal documents for vehicles under business ownership": "በንግድ ባለቤትነት ስር ላሉ ተሽከርካሪዎች ህጋዊ ሰነዶች",
        "Legal documents for motorcycles under business ownership": "በንግድ ባለቤትነት ስር ላሉ የሞተር ሳይክሎች ህጋዊ ሰነዶች",
        "Legal documents for construction machinery under business ownership": "በንግድ ባለቤትነት ስር ለግንባታ ማሽኖች ህጋዊ ሰነዶች",
        "Seller/lessor’s legal authority to lease": "ለሻጭ/አከራይ ህጋዊ የመከራየት ስልጣን",
        "Proof of property ownership or lease rights":"የንብረት ባለቤትነት ወይም የሊዝ መብቶች ማረጋገጫ",
        "Business registration documents for lessor":"ለአከራይ የንግድ ምዝገባ ሰነዶች",
        "Seller’s legal authority to sell property": "የሻጭ  ንብረትን ለመሸጥ ህጋዊ ስልጣን",
        "Proof of property ownership or lease rights": "የንብረት ባለቤትነት ወይም የሊዝ መብቶች ማረጋገጫ",
        "Business registration documents for seller if applicable": "አስፈላጊ ከሆነ ለሻጭ የንግድ ምዝገባ ሰነዶች",
        "Proof of property ownership or lease rights": "የንብረት ባለቤትነት ወይም የሊዝ መብቶች ማረጋገጫ",
        "Business registration documents for seller if applicable": "አስፈላጊ ከሆነ ለሻጭ የንግድ ምዝገባ ሰነዶች",
        "Identification of the representative(s)": "የተወካዩን (ዎች) መለየት",
        "Business registration and license (if applicable)": "የንግድ ምዝገባ እና ፈቃድ (የሚመለከተው ከሆነ)",
        "Corporate resolution or authorization letter from the entity": " ከህጋዊ አካል የድርጅት ውሳኔ ወይም የፈቃድ ደብዳቤ",
        "Proof of property or rights ownership being represented": "የሚወከልው ንብረት ወይም የመብት ባለቤትነት ማረጋገጫ",
        "Other relevant legal documents": "ሌሎች ተዛማጅ ህጋዊ ሰነዶች",
        "Full authorization or power of attorney for representation":"የውክልና ሙሉ ፍቃድ ወይም የውክልና ስልጣን",
        "Additional supporting documents relevant to the case": "ከጉዳዩ ጋር ተዛማጅነት ያላቸው ተጨማሪ ደጋፊ ሰነዶች",
        "Family relationship proof (e.g., marriage certificate, birth certificates)": "የቤተሰብ ግንኙነት ማረጋገጫ (ለምሳሌ የጋብቻ የምስክር ወረቀት፣ የልደት የምስክር ወረቀቶች)",
        "Legal authorization or power of attorney for representation": "የውክልና ህጋዊ ፍቃድ ወይም የውክልና ስልጣን",
        "Proof of ownership or rights related to family matters": "ከቤተሰብ ጉዳዮች ጋር የተያያዙ የባለቤትነት ማረጋገጫ ወይም መብቶች",
        "Supporting documents for the family-related matter": "ከቤተሰብ ጋር ለተያያዙ ጉዳዮች ደጋፊ ሰነዶች",
        "Identification of the parties and witnesses": "የፓርቲዎች እና ምስክሮች መታወቂያ",
        "Seller/lessor’s legal authority to lease": "ሻጭ/አከራይ ህጋዊ የመከራየት ስልጣን",
        "Proof of marriage certificate or spousal consent if applicable": "አስፈላጊ ከሆነ የጋብቻ የምስክር ወረቀት ወይም የትዳር ጓደኛ ፈቃድ ማረጋገጫ",
        "Ownership or map of the business property": "የንግድ ንብረቱ ባለቤትነት ወይም ካርታ",
        "Business registration and operating documents for the business entity": "ለንግድ ድርጅት የንግድ ምዝገባ እና የአሠራር ሰነዶች",
        "Ownership or property map": "የባለቤትነት ወይም የንብረት ካርታ",
        "Registration of the property if part of a business entity": "የንግድ አካል አካል ከሆነ የንብረቱ ምዝገባ",
        "Ownership or vehicle map": "የባለቤትነት ወይም የተሽከርካሪ ካርታ",
        "Business registration if applicable": "አስፈላጊ ከሆነ የንግድ ምዝገባ",
        "Ownership or property map of machinery": "የማሽን ባለቤትነት ወይም የንብረት ካርታ",
          'Debre Zeit Road Near Agona Cinema, Balkar Building': 'ደብረ ዘይት መንገድ አጎና ሲኒማ አጠገብ ባለኬር ህንፃ',
          'Sefere selam Near Ethio-Tebib Hospital': 'ሰፈረ ሰላም ኢትዮ-ጤቢ ሆስፒታል አጠገብ',
          'Megenagna Derartu Tulu Building': 'መገናኛ ድራርቱ ቱሉ ህንፃ',
          'Sidist Kilo around Egypt Embassy, International Leadership Institute, 2nd floor': 
            'ስድስት ኪሎ አካባቢ የግብጽ ኤምባሲ አጠገብ ዓለም አቀፍ አመራር ተቋም 2ኛ ፎቅ',
          'Around Ethiopian News Agency, Darele Building': 
            'ኢዜአ አካባቢ ዳርሌ ህንፃ',
          'Around Ayer Tena Zewditu Building': 'አየር ጤና አካባቢ ዘውዲቱ ህንፃ',
          'In Front of Kality Prison, Sheger Building 2nd floor': 
            'ቃሊቲ እስር ቤት ፊት ለፊት ሸገር ህንፃ 2ኛ ፎቅ',
          'Kazanchis In Front of Yordanos Hotel, Enat Building, 3rd Floor': 
            'ካዛንች ዮርዳኖስ ሆቴል ፊት ለፊት እናት ህንፃ 3ኛ ፎቅ',
          'Nefas Silk Lafto Sub City, Road From Anbessa Shoe to Kirkos Church, SD Building, 2nd Floor': 
            'ንፋስ ስልክ ላፍቶ ክፍለ ከተማ ከአንበሳ ጫማ ወደ ቁርቆስ ቤተክርስቲያን መንገድ ኤስዲ ህንፃ 2ኛ ፎቅ',
          'Near Mexico Square In front of Philips Building, “Chelelek Aslam Tower” 2nd Floor': 
            'ሜክሲኮ አደባባይ አጠገብ ፊሊፕስ ህንፃ ፊትለፊት ጨለለቅ አልሳም ታወር 2ኛ ፎቅ',
          'CMC Square Road that takes to Semit in front of Sun Shine, Building that hold CBE Semit Branch 1st floor': 
            'ሲኤምሲ አደባባይ ሰሚት መንገድ ሰንሻይን ፊትለፊት ኢትዮጵያ ንግድ ባንክ አለበት ህንፃ 1ኛ ፎቅ',
          'Near Mafi City Center, Dr Beyen Building 3rd floor': 
            'ማፊ ሲቲ ሴንተር አጠገብ ዶ/ር በየነ ህንፃ 3ኛ ፎቅ',
          'Merkato Gojam Berenda': 'መርካቶ ጎጃም በረንዳ',
          'Jemo 1 Delina 3rd Floor': 'ጀሞ 1 ደሊና ህንፃ 3ኛ ፎቅ',
          'Dire Dawa Management Council Building': 
            'ድሬዳዋ አስተዳደር ካውንስል ህንፃ',
            "Mesalemiya": 'መሳለሚያ',
            'Sidist Kilo': 'ስድስት ኪሎ',
            "Lancha": "ላንቻ",
            "VIP-Megenagna": "VIP-መገናኛ",
          "no_afternoon_slots":"ምንም የከሰዓት ሰዓት የለም",
        "no_morning_slots":"ምንም የጠዋት ሰዓት የለም"

      },
    },
  },
  lng: localStorage.getItem("language") || "am", // Default language
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
