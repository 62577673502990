import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts/authContext';
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../Header/QeteroHeader";
import { MdOutlineLanguage } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import FreeModal from "../Appointment/FreeModal";
import TermsAndConditions from "./TermsAndConditionsModal";

const Login = () => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [termsAndConditionsModal, setTermsAndConditionsModal] = useState(false);
  const { user, token,setToken } = useContext(AuthContext);
  // console.log(token, 'User Logged In');

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token, navigate]);


  const toggleLanguageDropdown = () => {
    setLanguageDropdownOpen(!languageDropdownOpen);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Updated pattern to allow both '911111111' and '0911111111'
    const phonePattern = /^0?9\d{8}$/;
    if (!phonePattern.test(phoneNumber)) {
      setError("Please enter a valid phone number starting with 9.");
      return;
    }

    setError("");
    setIsLoading(true);

    // Format phone number to ensure it starts with the correct format for +251
    const formattedPhoneNumber = phoneNumber.startsWith("0")
      ? phoneNumber.slice(1)
      : phoneNumber;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/auth/sendOTP`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ mobile: `+251${formattedPhoneNumber}` }), // Adjusted phone number format
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send verification code");
      }

      setCodeSent(true);
      navigate("/otp", {
        state: {
          mobile: formattedPhoneNumber,
        },
      });
    } catch (error) {
      console.error("Error sending verification code:", error);
      setError("Failed to send verification code. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div >
      <header className="flex justify-between align-middle p-2 ">
        <div className="flex justify-between py-2">
          <div className="flex">
            <Link to="/">
              <img
                loading="lazy"
                src="./qetero_logo_text_3.svg"
                className="w-18 h-10 "
                alt="DARS Logo"
              />
            </Link>
          </div>
        </div>
        <div className=" p-2">
        <button
          onClick={toggleLanguageDropdown}
          className="flex items-center px-2 py-1 rounded-full bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
        >
          <MdOutlineLanguage className="text-white w-4 h-4 mr-2" />
          <span className="text-white text-xs md:text-sm font-medium">
            {i18n.language === "en" ? "English" : "አማርኛ"}
          </span>
          <IoIosArrowDown className="text-white w-4 h-4 mr-2" />
        </button>

        {languageDropdownOpen && (
          <div className="origin-top-right absolute right-0 mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <button
                onClick={() => {
                  i18n.changeLanguage("en");
                  setLanguageDropdownOpen(false);
                }}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
              >
                English
              </button>
              <button
                onClick={() => {
                  i18n.changeLanguage("am");
                  setLanguageDropdownOpen(false);
                }}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
              >
                አማርኛ
              </button>
            </div>
          </div>
        )}
      </div>
      </header>

      <div className="relative min-h-screen flex flex-col justify-center bg-slate-50 overflow-hidden">
        {/* <img
            loading="lazy"
            className="w-full h-12 self-center"
            src="./qetero_logo_text_3.svg"
            alt="logo"
          /> */}
        <form
          // className="max-w-sm bg-white flex flex-col justify-center text-center mx-auto p-12 rounded-lg"
          onSubmit={handleSubmit}
        >
          <div className="max-w-md mx-auto text-center bg-white px-4 sm:px-8 py-14 rounded-xl shadow-md">
            <header className="mb-8">
              <h1 className="text-2xl font-bold mb-1">{t("login")}</h1>
            </header>
            <label
              htmlFor="phone-input"
              className="block mb-2 text-start font-medium text-gray-900 text-[15px] text-slate-500"
            >
              {t("phoneNumber")}:
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 start-0 top-0 flex items-center pl-3.5 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 19 18"
                >
                  <path d="M18 13.446a3.02 3.02 0 0 0-.946-1.985l-1.4-1.4a3.054 3.054 0 0 0-4.218 0l-.7.7a.983.983 0 0 1-1.39 0l-2.1-2.1a.983.983 0 0 1 0-1.389l.7-.7a2.98 2.98 0 0 0 0-4.217l-1.4-1.4a2.824 2.824 0 0 0-4.218 0c-3.619 3.619-3 8.229 1.752 12.979C6.785 16.639 9.45 18 11.912 18a7.175 7.175 0 0 0 5.139-2.325A2.9 2.9 0 0 0 18 13.446Z" />
                </svg>
              </div>
              <input
                type="text"
                id="phone-input"
                aria-describedby="helper-text-explanation"
                className={`bg-gray-50 border ${
                  error ? "border-red-500" : "border-gray-300"
                } text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5`}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                pattern="0?9[0-9]{8}" // Accepts either 9 or 09 at the start
                placeholder="0911111111 or 911111111"
                required
              />
            </div>
            {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
            <button
              type="submit"
              className="text-white w-full mt-12 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              disabled={isLoading}
            >
              {isLoading ? t("sending") : t("sendVerificationCode")}
            </button>
            {codeSent && (
              <p className="text-green-500 text-sm mt-2">
                {t("verificationCodeSent")}
              </p>
            )}
          </div>
        </form>
        <p
          className="text-center mt-4 text-green-600 cursor-pointer"
          onClick={() => setTermsAndConditionsModal(true)}
        >
          {t("termsAndConditions")}
        </p>
        <p
          className="text-center mt-4 text-green-600 cursor-pointer"
          onClick={() => navigate("/Help")}
        >
          {t("help")}
        </p>
      </div>
      <TermsAndConditions
        isOpen={termsAndConditionsModal}
        onClose={() => setTermsAndConditionsModal(false)}
      />
    </div>
  );
};

export default Login;
