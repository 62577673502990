import React, { createContext, useState, useEffect } from "react";
import {jwtDecode} from "jwt-decode"; // Removed braces

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState((val) => {
    try {
      const tokenAuth = localStorage.getItem("token");
      if(tokenAuth){
        return localStorage.getItem("token");
      }
      else {
        return val;
      }
      
    } catch (error) {
      console.error("Error accessing localStorage", error);
      return null;
    }
  });
  const [decoding, setDecoding] = useState(false);

  const fetchToken = async () => {
    try {
      const token = localStorage.getItem("token");
      setToken(token);
    } catch (error) {
      console.error("Error fetching token", error);
    }
  };

  const initializeAuth = async () => {
    if (token) {
      try {
        setDecoding(true);
        const decodedToken = jwtDecode(token);
        // console.log(decodedToken, 'Decoded Token');
        
        // console.log("user inside", decodedToken)
        setUser(decodedToken?.data);
      } catch (error) {
        console.error("Error decoding token", error);
      } finally {
        setDecoding(false);
      }
    }
  };

  useEffect(() => {
    const checkAuth = async () => {
      if (!token) {
        await fetchToken();
      } else {
        await initializeAuth();
      }
    };
    checkAuth();
  }, [token]);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        token,
        setToken,
        decoding,
        setDecoding,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider };
