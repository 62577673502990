import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaShoppingCart } from "react-icons/fa";
import { FaBuilding, FaBuildingColumns, FaGears, FaGift, FaHandshake, FaKey, FaUserTie } from "react-icons/fa6";
import { IoGrid } from "react-icons/io5";

const fetchServices = async (branchId) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/v1/branch_services/findServicesByBranchGuest?branch_id=${branchId}`
  );
  return response.data.services.map((service) => ({
    id: service.id,
    name: service.name,
    service_id: service.service_id, // Extract service_id from the API response
  }));
};

const getServiceIcon = (serviceName) => {
  switch (serviceName.toLowerCase()) {
    case "plc":
      return <FaBuilding  className="w-12 h-12 self-center text-blue-800" />;
    case "sales":
      return <FaShoppingCart  className="w-12 h-12 self-center text-blue-800" />;
    case "gift":
      return <FaGift  className="w-12 h-12 self-center text-blue-800" />;
    case "rental":
      return <FaKey  className="w-12 h-12 self-center text-blue-800" />;
    case "representation":
      return <FaUserTie  className="w-12 h-12 self-center text-blue-800" />;
    case "loan":
      return <FaHandshake  className="w-12 h-12 self-center text-blue-800" />;
    default:
      return <IoGrid  className="w-12 h-12 self-center text-blue-800" />;
  }
};

const ServiceSelection = ({
  selectedBranch,
  selectedService,
  selectedServiceOriginal,
  setSelectedService,
  setSelectedServiceOriginal,
  setSelectedServiceName,
  selectedAddress,
  nextStep,
  backStep,
  selectedBranchName,
}) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const [showAll, setShowAll] = useState(false);

  const {
    data: services,
    isLoading: servicesLoading,
    error: servicesError,
  } = useQuery({
    queryKey: ["services", selectedBranch],
    queryFn: () => fetchServices(selectedBranch),
    enabled: !!selectedBranch,
  });

  const filteredServices = services?.filter((service) =>
    service.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const displayedServices = showAll
    ? filteredServices
    : filteredServices?.slice(0, 9);

  return (
    <div className="bg-gray-100">

      {/* Service List */}
      <div className="flex flex-col justify-start bg-gray-100 p-2 container w-screen ">
        <div className="flex px-2 justify-center align-middle text-center bg-white border-gray-200 border rounded-xl py-2">
          <FaBuildingColumns className="w-10 h-10 text-gray-500 mr-4" />
          <div className="flex flex-col justify-start">
            <h2 className="font-semibold ml-2 line-clamp-2 text-lg text-gray-800">
              {t(selectedBranchName)} {t("branch")}
            </h2>
            <p className="font-normal ml-2 line-clamp-2 text-sm text-gray-800">
              {t(selectedAddress)} {t("branch")}
            </p>
          </div>
        </div>
        <div className="border rounded-xl bg-white border-gray-200 p-2 mt-2 ">
        <h2 className="text-pretty text-sm md:text-lg pb-2 text-gray-600 font-semibold ">{t("selectService")}</h2>

          <div className="grid grid-cols-3 bg-white md:grid-cols-4 lg:grid-cols-6 gap-4">
            {servicesLoading ? (
              Array.from({ length: 9 }).map((_, index) => (
                <div
                  key={index}
                  className="p-4 flex flex-col items-center justify-center border-2 border-gray-300 bg-white rounded-lg shadow-sm animate-pulse"
                >
                  <div className="h-6 w-20 bg-gray-200 rounded-md mb-2"></div>
                  <div className="h-4 w-16 bg-gray-200 rounded-md"></div>
                </div>
              ))
            ) : servicesError ? (
              <p className="text-red-500 text-2xl font-semibold text-center">
                {t("errorLoadingServices")}
              </p>
            ) : (
              displayedServices?.map((service) => (
                <div
                  key={service.id}
                  className={`p-2 bg-blue-300/5 flex flex-col border rounded-lg transition-transform transform hover:scale-105 shadow-md cursor-pointer hover:shadow-lg ${
                    selectedService === service.id
                      ? "bg-blue-200 border-blue-600 "
                      : "bg-white border-gray-300 text-gray-800 hover:bg-blue-50"
                  }`}
                  onClick={() => {
                    setSelectedService(service.id); // Set selected service ID
                    setSelectedServiceName(service.name);
                    setSelectedServiceOriginal(service.service_id); // Set original service ID
                    nextStep(); // Move to the next step
                  }}
                >
                  {getServiceIcon(service.name)}
                  <h3 className="text-md text-xs font-semibold line-clamp-1 text-center">
                    {t(service.name)}
                  </h3>
                </div>
              ))
            )}
          </div>
        </div>
        {/* Navigation Buttons */}
        <div className="flex justify-between items-center mt-2">
          <button
            onClick={backStep}
            className="bg-gray-400 text-white px-6 py-2 rounded-lg shadow-md hover:bg-gray-500 transition duration-300 ease-in-out"
          >
            {t("back")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ServiceSelection;
