import { jwtDecode } from "jwt-decode";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/authContext";

const OTPFormSignUp = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  //  const { setUser, setToken } = useContext(AuthContext);
  const [mobile, setMobile] = useState(state?.mobile);
  const inputsRef = useRef([]);
  const [otp, setOtp] = useState("");

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (
        !/^[0-9]{1}$/.test(e.key) &&
        e.key !== "Backspace" &&
        e.key !== "Delete" &&
        e.key !== "Tab" &&
        !e.metaKey
      ) {
        e.preventDefault();
      }

      if (e.key === "Delete" || e.key === "Backspace") {
        const index = inputsRef.current.indexOf(e.target);
        if (index > 0) {
          inputsRef.current[index - 1].value = "";
          inputsRef.current[index - 1].focus();
        }
      }
    };

    const handleInput = (e) => {
      const target = e.target;
      const index = inputsRef.current.indexOf(target);
      if (target.value) {
        if (index < inputsRef.current.length - 1) {
          inputsRef.current[index + 1].focus();
        } else {
          document.getElementById("submit-btn").focus();
        }
      }
      const otpValue = inputsRef.current.map((input) => input.value).join("");
      setOtp(otpValue);
    };

    const handleFocus = (e) => {
      e.target.select();
    };

    const handlePaste = (e) => {
      e.preventDefault();
      const text = e.clipboardData.getData("text");
      if (!new RegExp(`^[0-9]{${inputsRef.current.length}}$`).test(text)) {
        return;
      }
      const digits = text.split("");
      inputsRef.current.forEach(
        (input, index) => (input.value = digits[index])
      );
      document.getElementById("submit-btn").focus();
      setOtp(text);
    };

    inputsRef.current.forEach((input) => {
      input.addEventListener("input", handleInput);
      input.addEventListener("keydown", handleKeyDown);
      input.addEventListener("focus", handleFocus);
      input.addEventListener("paste", handlePaste);
    });

    return () => {
      inputsRef.current.forEach((input) => {
        input.removeEventListener("input", handleInput);
        input.removeEventListener("keydown", handleKeyDown);
        input.removeEventListener("focus", handleFocus);
        input.removeEventListener("paste", handlePaste);
      });
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/auth/verifyOTPGuest`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ mobile: `+251${mobile}`, otp }),
        }
      );

      if (response.ok) {
        // console.log("Verification successful");
        navigate(`/Profile?phone_number=+251${mobile}`);
      } else {
        console.error("Verification failed: " + data.message);
      }
    } catch (error) {
      console.error("An error occurred: " + error.message);
    }
  };

  return (
    <div className="relative min-h-screen flex flex-col justify-center bg-slate-50 overflow-hidden">
      <div className="w-full max-w-6xl mx-auto px-4 md:px-6 py-24">
        <div className="flex justify-center">
          <div className="max-w-md mx-auto text-center bg-white px-4 sm:px-8 py-10 rounded-xl shadow">
            <header className="mb-8">
              <h1 className="text-2xl font-bold mb-1">
                Mobile Phone Verification
              </h1>
              <p className="text-[15px] text-slate-500">
                Enter the 6-digit verification code that was sent to your phone
                number.
              </p>
            </header>
            <form id="otp-form" onSubmit={handleSubmit}>
              <div className="flex items-center justify-center gap-3">
                {Array.from({ length: 5 }).map((_, index) => (
                  <input
                    key={index}
                    type="text"
                    className="w-14 h-14 text-center text-2xl font-extrabold text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
                    pattern="\d*"
                    maxLength="1"
                    ref={(el) => (inputsRef.current[index] = el)}
                  />
                ))}
              </div>
              <div className="max-w-[260px] mx-auto mt-4">
                <button
                  id="submit-btn"
                  type="submit"
                  className="text-white w-full mt-12 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                  Verify Account
                </button>
              </div>
            </form>
            <div className="text-sm text-slate-500 mt-4">
              Didn't receive code?{" "}
              <a
                className="font-medium text-blue-500 hover:text-blue-600"
                href="#0"
              >
                Resend
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTPFormSignUp;
