import React, { useState, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import { useNavigate, useLocation } from "react-router-dom";
import "../../index.css";
import languages from "../language.json";
import { useLanguage } from "../../contexts/llanguageContext";

const Services = ({ title, description }) => {
  const {language, setLanguage } = useLanguage(); // Default language

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    // console.log(storedLanguage, "Stored");

    if (storedLanguage) {
      setLanguage(storedLanguage);
    }

    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token); // Decode the token
        setFirstname(decodedToken?.data?.user?.firstname || "");
        setId(decodedToken?.data?.user?.id || decodedToken?.data?.id || "");
      } catch (error) {
        console.error("Failed to decode token:", error);
      }
    }
  }, [navigate]);

  const handleVisitWebsite = () => {
    navigate("/appointment"); // Redirects to the landing page
  };

  return (
    <div className="flex flex-col w-full max-md:w-full mx-4 md:w-1/3 lg:w-1/4">
      <div className="flex flex-col grow justify-center text-stone-900 mt-5">
        <div className="flex flex-col px-5 py-10 bg-white rounded-xl shadow-lg">
          <div className="mt-2 text-xl font-bold text-center md:text-2xl">
            {title}
          </div>
          <div className="mt-3 text-base text-center md:text-md">
            {description}
          </div>
          <button
            onClick={handleVisitWebsite}
            className="justify-center px-10 py-3 text-base font-bold leading-6 text-white bg-pink-700 rounded-lg w-full mt-5 md:w-auto max-md:px-5 max-md:py-2 max-md:mt-3"
          >
            {languages[language].bookNow || "Book Now"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Services;
